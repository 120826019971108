import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SectionDivider from "../components/atomic/section-divider"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

export default function Referencie() {

  const data = useStaticQuery(graphql`{
  gyg: file(relativePath: {eq: "gyg_show_title.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  tjlforms: file(relativePath: {eq: "tjlforms-box.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  kindergeld: file(relativePath: {eq: "kindergeld-show-2.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  doku: file(relativePath: {eq: "doku-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  erriate: file(relativePath: {eq: "crossing.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  drivelog: file(relativePath: {eq: "drivelog-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  helske: file(relativePath: {eq: "helske-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  piqipi: file(relativePath: {eq: "piqipi-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  ultimuv: file(relativePath: {eq: "ultimuv-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  roomassistant: file(relativePath: {eq: "ra-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  tasty: file(relativePath: {eq: "tasty-show.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`)




  return (
    <Layout>
      <SEO title="Referencie" />
      
     
      <div className="subhero">   
      <div className="hero">
        <div className="container">
          <div className="row">
          <div className="col-12">
            <div className="title"><h1>Urobili sme už viac ako <span style={{color: "#F94166"}}>370 projektov.</span></h1></div>
            <p>Za niekoľko rokov existencie nášho tímu sme vytvorili veľa zaujímavých aplikácií, softvéru a taktiež sme pomohli ako menšia súčasť niečoho väčšieho.</p>
        
          </div>
          </div>
        </div>
      </div> 
      </div>  
    

      <div className="section-pd section-pd-bottom  white-bg ">
        <div className="container">
          <SectionDivider name="References" white={false}/>
          <div className="row mt40">
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/gyg" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Guzman Y Gomez</h2>
                      <p>Webová aplikácia pre známu Austrálsku sieť reštaurácií s rýchlim občerstvením.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.gyg.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Softvér na mieru" />  
                </AniLink>
              </div>
              
            </div>
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/tjl-app" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>TJ-Legal aplikácia</h2>
                      <p>Webová aplikácia pre spoločnosť, ktorá vracia dane svojim zákazníkom zo zahraničia.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.tjlforms.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Web aplikácia" />  
                </AniLink>
              </div>
              
            </div>
          </div>
          <div className="row mt40">
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/kindergeld" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Aplikácia Kindergeld</h2>
                      <p>Webová aplikácia pre získanie prídavkov na deti v Nemecku.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.kindergeld.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Webová aplikácia" />  
                </AniLink>
              </div>
              
            </div>
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/doku" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Aplikácia Doku</h2>
                      <p>Webová aplikácia pre Rakúsku developérsku spoločnosť.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.doku.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Webová aplikácia" />  
                </AniLink>
              </div>
            </div>
          </div>


          <div className="row mt40">
          <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/helske" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Helske - digital</h2>
                      <p>Celá digitálna prezencia materskej spoločnosti a jej dcérskych spoločností.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.helske.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Digitálne služby" />  
                </AniLink>
              </div>
            </div>
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/piqipi" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Eshop Piqipi</h2>
                      <p>Webová stránka s obchodom pre detské knihy Piqipi.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.piqipi.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="E-shop" />  
                </AniLink>
              </div>
              
            </div>
            
          </div>
          <div className="row mt40">
          <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/ultimuv" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Ultimuv web a webová aplikácia</h2>
                      <p>Web a webová aplikácia pre fyzioterapeutické pohybové centrá.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.ultimuv.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Web stránka" />  
                </AniLink>
              </div>
            </div>
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/tasty" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Tasty Burger - aplikácia</h2>
                      <p>Mobilná aplikácia s objednávkovým systémom pre Tasty reštauráciu.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.tasty.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Mobilná aplikácia" />  
                </AniLink>
              </div>
              
            </div>
            
          </div>
          <div className="row mt40">
          <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/roomassistant" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>RoomAssistant aplikácie</h2>
                      <p>Web, webová a mobilná aplikácia pre hotelového poslíčka RoomAssistant.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.roomassistant.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Mobilná aplikácia" />  
                </AniLink>
              </div>
            </div>
            <div className="col-6">
              <div className="ref-card-detail">
                <AniLink cover direction="left" to="/referencie/drivelog" bg="#000" duration={1}>
                  <div className="fade">
                    <div className="text">
                      <h2>Mobilná aplikácia DriveLog</h2>
                      <p>Mobilná aplikácia pre sledovanie firemných vozidiel a získavanie ich štatistík.</p>
                    </div>
                  </div>

                  <GatsbyImage
                    image={data.drivelog.childImageSharp.gatsbyImageData}
                    style={{"height":"500px"}}
                    objectFit="cover"
                    objectPosition="0% 0%"
                    alt="Mobilná aplikácia" />  
                </AniLink>
              </div>
              
            </div>
            
          </div>




        </div>
      
      </div>

      

    </Layout>
  );

}
